export const ICONS = {
  github: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path d="M8.00089 6.8756e-07C3.58038 -0.00183544 0 3.67409 0 8.21115C0 11.7989 2.23975 14.8487 5.35895 15.9688C5.77902 16.0771 5.71467 15.7705 5.71467 15.5612V14.1382C3.28902 14.4301 3.1907 12.7813 3.02804 12.5059C2.69914 11.9293 1.92157 11.7824 2.15395 11.507C2.70629 11.2151 3.26936 11.5804 3.9218 12.5701C4.3937 13.288 5.31427 13.1669 5.78081 13.0475C5.88269 12.616 6.10077 12.2304 6.40107 11.9311C3.88783 11.4684 2.84035 9.89305 2.84035 8.0202C2.84035 7.11132 3.13172 6.27588 3.70372 5.60202C3.33907 4.49116 3.73768 3.54005 3.79131 3.39867C4.82985 3.30319 5.90951 4.1625 5.99352 4.23043C6.5834 4.06702 7.25729 3.98072 8.01162 3.98072C8.76952 3.98072 9.4452 4.07069 10.0404 4.23594C10.2424 4.07804 11.2434 3.33991 12.2087 3.42988C12.2605 3.57127 12.6502 4.50035 12.307 5.59651C12.8862 6.27221 13.1811 7.11499 13.1811 8.02571C13.1811 9.90223 12.1265 11.4795 9.60608 11.9348C9.82196 12.1529 9.99337 12.413 10.1103 12.6999C10.2272 12.9867 10.2873 13.2947 10.2871 13.6057V15.6713C10.3014 15.8366 10.2871 16 10.5552 16C13.7209 14.9038 16 11.832 16 8.21299C16 3.67409 12.4178 6.8756e-07 8.00089 6.8756e-07Z" fill="white" />\n' +
    '</svg>\n',
  twitter: '<svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path d="M16 1.5162C15.5544 2.16889 15.0078 2.72527 14.3601 3.18535C14.3665 3.30875 14.3697 3.44821 14.3697 3.60375C14.3697 4.46794 14.2418 5.33318 13.9859 6.19947C13.73 7.06576 13.3392 7.89496 12.8134 8.68706C12.2876 9.47915 11.6612 10.1809 10.9344 10.7922C10.2075 11.4036 9.33376 11.8911 8.31316 12.2546C7.29257 12.6182 6.19877 12.8 5.03175 12.8C3.21044 12.8 1.53319 12.3158 0 11.3473C0.272123 11.3771 0.533112 11.392 0.782966 11.392C2.30453 11.392 3.66352 10.9305 4.85996 10.0074C4.15052 9.99455 3.51517 9.77948 2.9539 9.36213C2.39262 8.94479 2.00637 8.41151 1.79513 7.76228C2.00393 7.80145 2.21 7.82103 2.41335 7.82103C2.70606 7.82103 2.99408 7.78344 3.27742 7.70824C2.52032 7.55891 1.89224 7.18754 1.39318 6.59413C0.894134 6.00073 0.644613 5.31583 0.644613 4.53944V4.49943C1.10933 4.75283 1.60501 4.88762 2.13168 4.9038C1.68318 4.60933 1.32758 4.22534 1.0649 3.75182C0.802193 3.27829 0.670841 2.76586 0.670841 2.21451C0.670841 1.63303 0.818794 1.09155 1.1147 0.590092C1.93804 1.58514 2.93568 2.38041 4.10763 2.97591C5.27959 3.57141 6.53689 3.90214 7.87952 3.96809C7.82225 3.73305 7.79357 3.48778 7.79346 3.23228C7.79346 2.34015 8.11403 1.57841 8.75517 0.947045C9.39631 0.315682 10.1698 0 11.0757 0C12.0241 0 12.8229 0.3401 13.4722 1.0203C14.2141 0.875583 14.9087 0.613828 15.5561 0.235033C15.3066 1.00807 14.826 1.60441 14.1143 2.02406C14.7685 1.9475 15.397 1.77821 16 1.5162H16Z" fill="white" />\n' +
    '</svg>',
  telegram: '<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path d="M14.92 0.888158L0.747101 6.35349C-0.220143 6.74198 -0.214547 7.28156 0.56964 7.52217L4.2084 8.65729L12.6274 3.34544C13.0255 3.10323 13.3892 3.23352 13.0903 3.49892L6.26919 9.65491H6.26759L6.26919 9.65571L6.01818 13.4064C6.38589 13.4064 6.54817 13.2377 6.75441 13.0387L8.52182 11.32L12.1982 14.0355C12.876 14.4088 13.3628 14.2169 13.5315 13.408L15.9448 2.03446C16.1918 1.04404 15.5667 0.595586 14.92 0.888158Z" fill="white" />\n' +
    '</svg>',
  mail: '<svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path d="M0.00292969 2.68405L7.99993 6.68205L15.9969 2.68405C15.9673 2.17449 15.744 1.69554 15.3727 1.34528C15.0015 0.995014 14.5103 0.799955 13.9999 0.800049H1.99993C1.48951 0.799955 0.998377 0.995014 0.627107 1.34528C0.255838 1.69554 0.0325338 2.17449 0.00292969 2.68405Z"  fill="white"/>\n' +
    '<path d="M16 4.91797L8 8.91797L0 4.91797V10.8C0 11.3304 0.210714 11.8391 0.585786 12.2142C0.960859 12.5893 1.46957 12.8 2 12.8H14C14.5304 12.8 15.0391 12.5893 15.4142 12.2142C15.7893 11.8391 16 11.3304 16 10.8V4.91797Z" fill="#80808F"/>\n' +
    '</svg>',
  instagram: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path d="M7.99995 5.91736C6.85308 5.91736 5.91714 6.8533 5.91714 8.00017C5.91714 9.14705 6.85308 10.083 7.99995 10.083C9.14683 10.083 10.0828 9.14705 10.0828 8.00017C10.0828 6.8533 9.14683 5.91736 7.99995 5.91736ZM14.2468 8.00017C14.2468 7.13767 14.2546 6.28298 14.2062 5.42205C14.1578 4.42205 13.9296 3.53455 13.1984 2.8033C12.4656 2.07048 11.5796 1.84392 10.5796 1.79548C9.71714 1.74705 8.86245 1.75486 8.00151 1.75486C7.13901 1.75486 6.28433 1.74705 5.42339 1.79548C4.42339 1.84392 3.53589 2.07205 2.80464 2.8033C2.07183 3.53611 1.84526 4.42205 1.79683 5.42205C1.74839 6.28455 1.7562 7.13923 1.7562 8.00017C1.7562 8.86111 1.74839 9.71736 1.79683 10.5783C1.84526 11.5783 2.07339 12.4658 2.80464 13.197C3.53745 13.9299 4.42339 14.1564 5.42339 14.2049C6.28589 14.2533 7.14058 14.2455 8.00151 14.2455C8.86401 14.2455 9.7187 14.2533 10.5796 14.2049C11.5796 14.1564 12.4671 13.9283 13.1984 13.197C13.9312 12.4642 14.1578 11.5783 14.2062 10.5783C14.2562 9.71736 14.2468 8.86267 14.2468 8.00017ZM7.99995 11.2049C6.22651 11.2049 4.79526 9.77361 4.79526 8.00017C4.79526 6.22673 6.22651 4.79548 7.99995 4.79548C9.77339 4.79548 11.2046 6.22673 11.2046 8.00017C11.2046 9.77361 9.77339 11.2049 7.99995 11.2049ZM11.3359 5.41267C10.9218 5.41267 10.5875 5.0783 10.5875 4.66423C10.5875 4.25017 10.9218 3.9158 11.3359 3.9158C11.75 3.9158 12.0843 4.25017 12.0843 4.66423C12.0845 4.76256 12.0652 4.85993 12.0276 4.95079C11.99 5.04166 11.9349 5.12421 11.8654 5.19373C11.7959 5.26326 11.7133 5.31838 11.6225 5.35595C11.5316 5.39352 11.4342 5.4128 11.3359 5.41267Z" fill="white" />\n' +
    '</svg>',
  gitbook: '<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 64 64" height="16" viewBox="0 0 64 64" width="16">\n' +
    '    <switch>\n' +
    '        <path d="m28.8 47.4c1 0 1.9.8 1.9 1.9 0 1-.8 1.9-1.9 1.9-1 0-1.9-.8-1.9-1.9s.9-1.9 1.9-1.9m29.4-11.6c-1 0-1.9-.8-1.9-1.9 0-1 .8-1.9 1.9-1.9 1 0 1.9.8 1.9 1.9 0 1-.9 1.9-1.9 1.9m0-7.7c-3.2 0-5.8 2.6-5.8 5.8 0 .6.1 1.2.3 1.8l-19.1 10.2c-1.1-1.6-2.9-2.5-4.8-2.5-2.2 0-4.2 1.3-5.2 3.2l-17.2-9c-1.8-1-3.2-3.9-3-6.7.1-1.4.6-2.5 1.3-2.9.5-.3 1-.2 1.7.1l.1.1c4.6 2.4 19.5 10.2 20.1 10.5 1 .4 1.5.6 3.2-.2l30.8-16c.5-.2 1-.6 1-1.3 0-.9-.9-1.3-.9-1.3-1.8-.8-4.5-2.1-7.1-3.3-5.6-2.6-12-5.6-14.8-7.1-2.4-1.3-4.4-.2-4.7 0l-.7.3c-12.7 6.4-29.5 14.7-30.5 15.3-1.7 1-2.8 3.1-2.9 5.7-.2 4.1 1.9 8.4 4.9 9.9l18.2 9.4c.4 2.8 2.9 5 5.7 5 3.2 0 5.7-2.5 5.8-5.7l20-10.8c1 .8 2.3 1.2 3.6 1.2 3.2 0 5.8-2.6 5.8-5.8 0-3.3-2.6-5.9-5.8-5.9" fill="white"/>\n' +
    '    </switch>\n' +
    '</svg>',
  reddit: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path d="M15 8.29017C15 7.41654 14.3011 6.71763 13.4275 6.80499C13.078 6.80499 12.6412 6.97972 12.3791 7.24181C11.156 6.45554 9.75819 5.93136 8.27301 5.93136L8.97192 2.61154L11.2434 3.13573C11.2434 3.74727 11.7676 4.18409 12.3791 4.18409C12.9906 4.18409 13.4275 3.65991 13.4275 3.04836C13.4275 2.43682 12.9033 2 12.2917 2C11.8549 2 11.5055 2.26209 11.3307 2.61154L8.88456 2H8.70983C8.62247 2 8.62246 2.08736 8.62246 2.17473L7.74883 5.93136C6.26365 5.93136 4.86583 6.36817 3.64275 7.24181C3.0312 6.63026 2.0702 6.63026 1.45866 7.24181C0.847114 7.85335 0.847114 8.81435 1.45866 9.4259C1.54602 9.51326 1.72075 9.68799 1.89548 9.68799V10.1248C1.89548 12.4836 4.60374 14.4056 8.01092 14.4056C11.4181 14.4056 14.1264 12.4836 14.1264 10.1248V9.68799C14.6505 9.4259 15 8.90172 15 8.29017ZM4.51638 9.4259C4.51638 8.81435 5.04056 8.37753 5.56474 8.37753C6.17629 8.37753 6.6131 8.90172 6.6131 9.4259C6.6131 9.95008 6.08892 10.4743 5.56474 10.4743C4.9532 10.4743 4.51638 10.0374 4.51638 9.4259ZM10.6318 12.3089C9.84555 12.8331 8.97192 13.1825 8.01092 13.0952C7.04992 13.0952 6.17629 12.8331 5.39002 12.3089C5.30265 12.2215 5.30265 12.0468 5.39002 11.8721C5.47738 11.7847 5.65211 11.7847 5.73947 11.8721C6.35101 12.3089 7.13729 12.571 7.92356 12.4836C8.70983 12.571 9.4961 12.3089 10.1076 11.8721C10.195 11.7847 10.3697 11.7847 10.5445 11.8721C10.7192 11.9594 10.7192 12.1342 10.6318 12.3089ZM10.3697 10.4743C9.75819 10.4743 9.32137 9.95008 9.32137 9.4259C9.32137 8.90172 9.84555 8.37753 10.3697 8.37753C10.9813 8.37753 11.4181 8.90172 11.4181 9.4259C11.5055 10.0374 10.9813 10.4743 10.3697 10.4743Z" fill="white"/>\n' +
    '</svg>',
  medium: '<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path d="M3.35663 4.84597C3.36432 4.76945 3.35383 4.69219 3.32603 4.62049C3.29823 4.54878 3.25389 4.48465 3.19663 4.43331L2.01663 3.01131V2.79797H5.68197L8.5153 9.01131L11.006 2.79797H14.5V3.01064L13.49 3.97797C13.4477 4.0109 13.415 4.05454 13.3953 4.10436C13.3756 4.15419 13.3696 4.20838 13.378 4.26131V11.372C13.3695 11.4249 13.3754 11.4791 13.3951 11.529C13.4148 11.5788 13.4476 11.6225 13.49 11.6553L14.476 12.6226V12.8353H9.51863V12.6226L10.538 11.6313C10.6393 11.5313 10.6393 11.5013 10.6393 11.3486V5.60064L7.79997 12.812H7.41663L4.1113 5.60064V10.4333C4.08397 10.6366 4.1513 10.8413 4.29463 10.9886L5.62263 12.6V12.8126H1.8573V12.6L3.1853 10.9886C3.25516 10.9158 3.30704 10.8277 3.33679 10.7313C3.36654 10.6349 3.37333 10.5328 3.35663 10.4333V4.84597Z" fill="white"/>\n' +
    '</svg>\n'
}
