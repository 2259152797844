<template>
  <div class="logo p-d-inline-flex p-ai-center">
    <div class="logo-icon p-d-flex p-ai-center">
      <svg width="34" height="29" viewBox="0 0 34 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.7257 0L29.3434 7.18458V21.6256L16.7257 28.86L4.10791 21.6256V7.18458L16.7257 0ZM7.04227 8.88605V19.9299L16.7257 25.482L26.4091 19.9299V8.88605L16.7257 3.37231L7.04227 8.88605Z" fill="white"/>
        <path d="M33.4517 4.13428V21.6053H30.5174V9.1976L16.7721 17.0873L2.93436 9.19033V21.6053H0V4.14155L16.7679 13.7108L33.4517 4.13428Z" fill="white"/>
      </svg>
    </div>
    <div class="logo-text p-d-inline-flex p-ai-center p-ml-2">
      <svg width="106" height="10" viewBox="0 0 106 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.362793 0.340454H2.78643L5.38634 6.01518H5.25414L7.85405 0.340454H10.1896V9.62236H8.47098V2.27602H8.73538L5.827 8.61059H4.72535L1.86104 2.32001H2.03731V9.62236H0.362793V0.340454Z" fill="white"/>
        <path d="M11.8643 9.62236L15.037 0.340454H17.1522L20.1928 9.62236H18.4301L17.7691 7.64281H14.332L13.671 9.62236H11.8643ZM14.7286 6.32311H17.3285L16.0065 2.32001L14.7286 6.32311Z" fill="white"/>
        <path d="M21.8232 9.62236V0.340454H25.2604C26.3621 0.340454 27.2434 0.604396 27.8162 1.13228C28.3891 1.66016 28.6976 2.32001 28.6976 3.19981C28.6976 4.03562 28.3891 4.73946 27.8162 5.22335C27.2434 5.75124 26.3621 6.01518 25.2604 6.01518H23.4978V9.62236H21.8232ZM23.4978 4.87143H25.0841C25.7011 4.87143 26.1858 4.73946 26.4943 4.43153C26.8027 4.16759 26.979 3.77168 26.979 3.33178C26.979 2.89188 26.8468 2.49597 26.5383 2.23203C26.2299 1.96809 25.8333 1.79213 25.2604 1.79213H23.4978V4.87143ZM26.8468 9.62236L24.3791 5.26734H26.1858L29.0501 9.62236H26.8468Z" fill="white"/>
        <path d="M34.2492 9.79835C33.5882 9.79835 33.0153 9.66638 32.4865 9.44643C31.9577 9.22647 31.5171 8.87455 31.1645 8.43465C30.812 7.99475 30.6357 7.37889 30.6357 6.67505H32.3103C32.3103 7.02697 32.3984 7.3349 32.5747 7.55485C32.7509 7.7748 32.9713 7.95076 33.2797 8.08273C33.5882 8.2147 33.8966 8.25869 34.2492 8.25869C34.6017 8.25869 34.9102 8.2147 35.1746 8.12672C35.439 8.03874 35.6593 7.90677 35.7915 7.73081C35.9678 7.55485 36.0118 7.37889 36.0118 7.11495C36.0118 6.80702 35.9237 6.54308 35.7474 6.36712C35.5712 6.19116 35.2627 6.0152 34.822 5.88323L32.9713 5.1354C32.2662 4.87146 31.6933 4.51954 31.3408 4.16762C30.9883 3.81569 30.7679 3.28781 30.7679 2.62796C30.7679 1.88013 31.0764 1.26427 31.6493 0.780379C32.2662 0.296488 33.0594 0.0765381 34.161 0.0765381C35.2186 0.0765381 36.0118 0.296488 36.5847 0.780379C37.1576 1.26427 37.466 1.92412 37.5541 2.80392H35.8796C35.8356 2.452 35.6593 2.14407 35.3949 1.92412C35.1305 1.70417 34.7339 1.5722 34.161 1.5722C33.6763 1.5722 33.3238 1.66018 33.0153 1.88013C32.7509 2.10008 32.6187 2.32003 32.6187 2.62796C32.6187 2.8919 32.7069 3.06786 32.8831 3.24382C33.0594 3.41978 33.3238 3.55175 33.6763 3.68373L35.7034 4.47555C36.4084 4.73949 36.9372 5.09141 37.2897 5.48732C37.6423 5.88323 37.8626 6.41111 37.8626 7.02697C37.8626 7.59884 37.7304 8.08273 37.4219 8.47864C37.1135 8.87455 36.7169 9.18248 36.1881 9.44643C35.7034 9.71037 35.0424 9.79835 34.2492 9.79835Z" fill="white"/>
        <path d="M43.9443 9.62241V0.780396H49.2764V1.48424H44.7816V4.78349H49.012V5.48734H44.7816V8.87457H49.2764V9.57841H43.9443V9.62241Z" fill="white"/>
        <path d="M54.8285 9.75436C54.0353 9.75436 53.3743 9.53441 52.8455 9.1385C52.2727 8.74259 51.8761 8.17072 51.5676 7.46688C51.2592 6.76304 51.127 5.97122 51.127 5.09142C51.127 4.12364 51.3032 3.28782 51.6117 2.62797C51.9642 1.96812 52.4049 1.44024 52.9777 1.13231C53.5506 0.824382 54.1675 0.604431 54.8726 0.604431C55.7539 0.604431 56.503 0.824381 57.0759 1.30827C57.6487 1.74817 58.0453 2.36403 58.3097 3.06787L57.4284 3.28782C57.2081 2.67196 56.8996 2.23206 56.459 1.88014C56.0183 1.52822 55.4895 1.35226 54.9166 1.35226C54.4319 1.35226 53.9472 1.48423 53.5065 1.79216C53.0659 2.0561 52.7133 2.496 52.4489 3.06787C52.1845 3.63975 52.0523 4.2996 52.0523 5.13541C52.0523 5.88324 52.1405 6.54309 52.3608 7.11496C52.5811 7.68683 52.8896 8.17072 53.2862 8.52264C53.7269 8.87456 54.2557 9.05052 54.8726 9.05052C55.6217 9.05052 56.2386 8.83057 56.7234 8.43466C57.164 8.03875 57.4284 7.46688 57.4284 6.80703H58.3538C58.3538 7.42289 58.2216 7.95077 57.9131 8.39067C57.6047 8.83057 57.2081 9.18249 56.6793 9.44643C56.0624 9.66638 55.4895 9.75436 54.8285 9.75436Z" fill="white"/>
        <path d="M63.4657 9.75439C62.7607 9.75439 62.1437 9.57843 61.5709 9.2705C60.998 8.91857 60.5574 8.43468 60.2489 7.77483C59.9404 7.11498 59.7642 6.23518 59.7642 5.22341C59.7642 4.21164 59.9404 3.33183 60.2489 2.67198C60.5574 2.01213 61.0421 1.48425 61.5709 1.13233C62.1437 0.780409 62.7607 0.648438 63.4657 0.648438C64.1708 0.648438 64.7877 0.824399 65.3606 1.13233C65.9334 1.48425 66.3741 1.96814 66.6826 2.67198C66.991 3.33183 67.1673 4.21164 67.1673 5.22341C67.1673 6.23518 66.991 7.11498 66.6826 7.77483C66.3741 8.43468 65.8894 8.96256 65.3606 9.2705C64.8318 9.57843 64.1708 9.75439 63.4657 9.75439ZM63.4657 9.00655C64.3471 9.00655 65.0521 8.69862 65.5809 8.03877C66.1097 7.42291 66.33 6.45513 66.33 5.22341C66.33 3.99168 66.0656 3.06789 65.5809 2.40804C65.0521 1.74819 64.3911 1.44026 63.4657 1.44026C62.5844 1.44026 61.8793 1.79218 61.3505 2.45203C60.8218 3.11188 60.5574 4.07967 60.5574 5.31139C60.5574 6.54311 60.8218 7.51089 61.3505 8.12675C61.8793 8.69862 62.5844 9.00655 63.4657 9.00655Z" fill="white"/>
        <path d="M69.4146 0.780396H70.4721L75.2313 8.56664H75.1431V0.780396H75.9804V9.62241H74.9669L70.1196 1.70419H70.2518V9.62241H69.4146V0.780396Z" fill="white"/>
        <path d="M81.9731 9.75439C81.268 9.75439 80.6511 9.57843 80.0782 9.2705C79.5053 8.91857 79.0647 8.43468 78.7562 7.77483C78.4478 7.11498 78.2715 6.23518 78.2715 5.22341C78.2715 4.21164 78.4478 3.33183 78.7562 2.67198C79.0647 2.01213 79.5494 1.48425 80.0782 1.13233C80.6511 0.780409 81.268 0.648438 81.9731 0.648438C82.6781 0.648438 83.295 0.824399 83.8679 1.13233C84.4408 1.48425 84.8814 1.96814 85.1899 2.67198C85.4984 3.33183 85.6746 4.21164 85.6746 5.22341C85.6746 6.23518 85.4984 7.11498 85.1899 7.77483C84.8814 8.43468 84.3967 8.96256 83.8679 9.2705C83.3391 9.57843 82.6781 9.75439 81.9731 9.75439ZM81.9731 9.00655C82.8544 9.00655 83.5594 8.69862 84.0882 8.03877C84.617 7.42291 84.8374 6.45513 84.8374 5.22341C84.8374 3.99168 84.573 3.06789 84.0882 2.40804C83.5594 1.74819 82.8984 1.44026 81.9731 1.44026C81.0917 1.44026 80.3867 1.79218 79.8579 2.45203C79.3291 3.11188 79.0647 4.07967 79.0647 5.31139C79.0647 6.54311 79.3291 7.51089 79.8579 8.12675C80.3867 8.69862 81.0917 9.00655 81.9731 9.00655Z" fill="white"/>
        <path d="M87.9663 0.780396H89.1561L92.5051 7.24694H92.417L95.766 0.780396H96.9558V9.62241H96.1186V1.57222H96.2948L92.7255 8.43467H92.1526L88.6714 1.61621H88.8036V9.62241H87.9663V0.780396Z" fill="white"/>
        <path d="M98.542 0.780396H99.5555L102.332 5.31138H102.067L104.843 0.780396H105.857L102.64 5.97123V9.57841H101.803V5.97123L98.542 0.780396Z" fill="white"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Logo'
}
</script>

<style scoped lang="scss">

  .logo-icon {
    svg {
      width: 34px;
      height: 29px;
    }
  }
  .mobile .logo-icon {
    svg {
      width: 22px;
      height: 20px;
    }
  }
</style>
